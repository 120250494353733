export const setTutorialViewed = (status) => {
    localStorage.setItem('tutorial_viewed', status);
};

export const getTutorialViewed = () => {
    return localStorage.getItem('tutorial_viewed');
};

export const tutorialIsViewed = () => {
    return getTutorialViewed() === "true";
};
